<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: Seven
 * @Date: 2022-04-21 16:40:38
 * @LastEditors: Seven
 * @LastEditTime: 2022-04-21 16:44:32
-->
<template>
  <a-layout style="padding: 10px 10px 10px;flex:1" class="layout-bg">
    <!-- <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>Home</a-breadcrumb-item>
      <a-breadcrumb-item>List</a-breadcrumb-item>
      <a-breadcrumb-item>App</a-breadcrumb-item>
    </a-breadcrumb> -->
    <a-layout-content
      :style="{
        background: '#fff',
        padding:'10px',
        margin: 0,
        minHeight: '280px',
        overflow: 'initial',
        flex:1,
        display:'flex',
        flexDirection:'column'
      }"
    >
      <slot></slot>
    </a-layout-content>
  </a-layout>
</template>

<script>
export default {};
</script>

<style>
.layout-bg{
  background:#f2f6fb;
}
</style>