<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: Seven
 * @Date: 2022-04-21 16:40:38
 * @LastEditors: Seven
 * @LastEditTime: 2022-04-21 16:41:15
-->
<template>
  <div class="home_container">
    <!-- <router-view></router-view> -->
    <a-layout id="components-layout-demo-top-side-2">
      <!-- 菜单栏 -->
      <uniSider></uniSider>
      <a-layout :style="{ flex: '1' }">
        <!-- 头部 -->
        <uniHeader></uniHeader>
        <!-- 主内容区域 -->
        <a-spin v-show="microAppLoading" class="content-loading" size="large" />
        <uniContent v-show="!microAppLoading">
          <!-- 循环生成容器，每一个微应用使用独立的容器，自己控制微应用的显隐 -->
          <div
            class="childrenContainer"
            v-for="item in microApps"
            :key="item.name"
            :id="item.container.slice(1)"
          ></div>
          <!-- childrenContainer容器展示子应用 -->
          <!-- <div id="childrenContainer"></div> -->
        </uniContent>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import uniHeader from "@/components/uniHeader.vue";
import uniSider from "@/components/uniSider.vue";
import uniContent from "@/components/uniContent.vue";
import CONFIG from "../../micro-config";
import { mapMutations, mapState } from "vuex";
import { doLoadMicroApp, getMicroApp } from "../../core/MicroApp";

export default {
  data() {
    return {
      microApps: CONFIG.microApps,
      microAppLoading: false,
    };
  },
  computed: {
    ...mapState("user", ["routes"]),
  },
  components: {
    uniHeader,
    uniSider,
    uniContent,
  },
  mounted() {
    this.laodRoute();
  },
  methods: {
    //加载第一个路由
    async laodRoute() {

      this.microAppLoading = true;
      try {
        if (this.$route.path == "/home/") {
          if (Array.isArray(this.routes) && this.routes.length > 0) {
            const firstRoutes = this.routes[0];
            console.log("firstRoutes", firstRoutes);
            await doLoadMicroApp(firstRoutes.appName);
            this.$router.push(firstRoutes.routerPath);
          }
        } else {
          const microApps = getMicroApp();

          //存在微应用
          const conf = microApps.find(
            (item) => this.$route.path.indexOf(item.name) !== -1
          );

          if (conf) {
            await doLoadMicroApp(conf.name);
          }
        }
      } catch (error) {}

      this.microAppLoading = false;
    },
  },
};
</script>

<style lang='scss' scoped>
.home_container {
  height: 100%;
  display: flex;
  overflow-x: hidden;
}
.content-loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.childrenContainer {
  overflow-y: auto;
}
</style>