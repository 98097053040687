var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout',{staticClass:"layout-bg",staticStyle:{"padding":"10px 10px 10px","flex":"1"}},[_c('a-layout-content',{style:({
      background: '#fff',
      padding:'10px',
      margin: 0,
      minHeight: '280px',
      overflow: 'initial',
      flex:1,
      display:'flex',
      flexDirection:'column'
    })},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }