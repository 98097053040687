var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-sider',{style:({
    overflow: 'hidden',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  }),attrs:{"collapsible":"","trigger":null,"theme":"light"},model:{value:(_vm.isMenuCollapsed),callback:function ($$v) {_vm.isMenuCollapsed=$$v},expression:"isMenuCollapsed"}},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":"https://cdn.lifesea.com/0218%2FWechatIMG210.png","alt":""}}),(!_vm.isMenuCollapsed)?_c('a-dropdown',{staticClass:"system-title",attrs:{"trigger":['click']}},[_c('a',{staticClass:"ant-dropdown-link",on:{"click":function($event){$event.preventDefault();}}},[_c('span',{staticClass:"logoName"},[_vm._v("数智健康管理服务平台")])])]):_vm._e()],1),_c('a-menu',{staticClass:"menu",style:({
      borderRight: 0,
      overflowY: 'auto',
      overflowX: 'hidden',
      flex: '1',
    }),attrs:{"theme":"light","mode":"inline","default-selected-keys":['1'],"open-keys":_vm.openKeys},on:{"click":_vm.handleMenuItem,"openChange":_vm.onOpenChange}},[_vm._l((_vm.menuData),function(item,index){return [(item.children.length==0)?_c('a-menu-item',{key:index,staticClass:"root-menu"},[(item.meta.isOpen == 1)?_c('div',{staticStyle:{"width":"100%"},on:{"click":function($event){return _vm.gotoPath(item.routerPath)}}},[(item.meta.icon)?_c('a-icon',{attrs:{"type":item.meta.icon}}):_c('a-icon',{attrs:{"type":"menu"}}),_c('span',[_vm._v(_vm._s(item.name))])],1):_c('div',[(item.meta.icon)?_c('a-icon',{attrs:{"type":item.meta.icon}}):_c('a-icon',{attrs:{"type":"menu"}}),_c('router-link',{staticClass:"root-link",attrs:{"to":item.routerPath}},[_vm._v(_vm._s(item.name)+" ")])],1)]):_vm._e(),(item.children.length>0)?_c('a-sub-menu',{key:item.cd},[_c('span',{attrs:{"slot":"title"},slot:"title"},[(item.meta.icon)?_c('a-icon',{attrs:{"type":item.meta.icon}}):_c('a-icon',{attrs:{"type":"menu"}}),_c('span',[_vm._v(_vm._s(item.name))])],1),_vm._l((item.children),function(child,i){return [_c('a-menu-item',{key:child.cd},[_c('router-link',{attrs:{"to":child.routerPath}},[_vm._v(_vm._s(child.name)+" ")])],1)]})],2):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }