<template>
  <a-layout-header
    class="uni-header"
    style="background: #fff; padding: 0; display: flex"
    theme="light"
  >
    <div
      mode="horizontal"
      :style="{ lineHeight: '64px', flex: 1, display: 'flex' }"
      theme="light"
    >
      <a-icon
        class="trigger"
        :type="isMenuCollapsed ? 'menu-unfold' : 'menu-fold'"
        @click="onCollapsed"
      />
      <uniTabs v-if="isTabView" class="uniTabs"></uniTabs>
    </div>
    <div class="right-part">
      <div class="notice">
        <a-badge class="alert" :dot="true">
          <img src="@/assets/img/alert.png" />
        </a-badge>
        <div class="text">通知</div>
      </div>
      <div class="line"></div>
      <div style="cursor: pointer" class="person-part">
        <a-avatar class="avatar" />
        <!-- <a-popover class="popover">
          <template #content>
            <p class="item">个人中心</p>
            <p class="item">设置</p>
          </template>
          <span>{{userName}}</span>
        </a-popover> -->
        <span>{{userName}}</span>
      </div>
      <div style="cursor: pointer" @click="loginout">退出</div>
    </div>
  </a-layout-header>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import uniTabs from "./uniTabs.vue";
import CONFIG from "../config";
export default {
  components: { uniTabs },
  data() {
    return {
      pageList: [],
      isTabView:false,
      userName:""
    };
  },
  computed: {
    ...mapState("user", [
      "isMenuCollapsed",
      "multiPage",
      "routes",
      "activePage",
    ]),
  },
  created() {
    this.isTabView = CONFIG.isTabView
    this.userName = localStorage.getItem("userName")
  },
  methods: {
    ...mapMutations("user", [
      "changeIsMenuCollapsed",
      "deleteMultiPage",
      "setActivePage",
    ]),
    onCollapsed() {
      this.changeIsMenuCollapsed();
    },
    handleMenuItem(item) {
      this.$router.push(item.activeRule);
    },
    // 退出
    loginout() {
      this.$store.dispatch("user/loginout").then(() => {
        window.location.href = `/login?cdBdmd=${localStorage.cdBdmd}&cdTet=${localStorage.cdTet || localStorage.cdBdmd}`;
      });
    },
    handleClose(removedTag) {
      if (this.multiPage.length === 1)
        return this.$message.warning("这是最后一页，不能再关闭了啦");
      let currentProfileIndex = (this.multiPage || []).findIndex(
        (profile) => profile.cd === removedTag.cd
      );
      this.deleteMultiPage(removedTag);
      console.log(9999, this.multiPage, removedTag);

      console.log(currentProfileIndex);
    },
  },
};
</script>

<style lang='scss' scoped>
/deep/ .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

/deep/ .trigger:hover {
  color: #1890ff;
}
.uni-header {
  .box {
    display: flex;
    div {
      margin-right: 20px;
      font-size: 16px;
      cursor: pointer;
    }
    .active {
      color: #4088fe;
    }
  }
  .right-part {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 34px;
    .notice {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 18px;
      height: 100%;
      cursor: pointer;
      .alert {
        margin-right: 6px;
        img {
          width: 24px;
        }
      }
      .text {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #29364a;
        line-height: 19px;
      }
    }
    .line {
      width: 1px;
      height: 12px;
      border: 0.5px solid #9a9db3;
    }
    .person-part {
      margin-right: 30px;
      /deep/ .popover {
        .item {
          cursor: pointer;
        }
      }
      .avatar {
        margin-right: 10px;
        margin-left: 14px;
      }
    }
  }
  .uniTabs {
    width: 750px;
    overflow-x: scroll;
    display: flex;
    align-items: center;
    /deep/ .ant-tabs-bar{
      margin:0px;
      border-bottom:0px;
    }
  }
}
</style>
