<template>
  <a-layout-sider
    v-model="isMenuCollapsed"
    collapsible
    :trigger="null"
    theme="light"
    :style="{
      overflow: 'hidden',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
    }"
  >
    <div class="logo">
      <img src="https://cdn.lifesea.com/0218%2FWechatIMG210.png" alt="" />
      <a-dropdown class="system-title" :trigger="['click']" v-if="!isMenuCollapsed">
        <a class="ant-dropdown-link" @click.prevent>
          <span class="logoName">数智健康管理服务平台</span>
          <!-- <a-icon class="trigger" type="caret-down" /> -->
        </a>
        <!-- <template #overlay>
          <a-menu>
            <a-menu-item key="3">3rd menu item（disabled）</a-menu-item>
          </a-menu>
        </template> -->
      </a-dropdown>
    </div>

    <a-menu
      class="menu"
      theme="light"
      mode="inline"
      :default-selected-keys="['1']"
      :style="{
        borderRight: 0,
        overflowY: 'auto',
        overflowX: 'hidden',
        flex: '1',
      }"
      :open-keys="openKeys"
      @click="handleMenuItem"
      @openChange="onOpenChange"
    >
      <template v-for="(item, index) in menuData">
        <a-menu-item class="root-menu" :key="index" v-if="item.children.length==0">
            <div style="width: 100%;" v-if="item.meta.isOpen == 1" @click="gotoPath(item.routerPath)">
              <a-icon v-if="item.meta.icon" :type="item.meta.icon" />
              <a-icon v-else type="menu" />
              <span>{{ item.name }}</span>
            </div>
            <div v-else>
              <a-icon v-if="item.meta.icon" :type="item.meta.icon" />
              <a-icon v-else type="menu" />
              <router-link class="root-link" :to="item.routerPath"
                  >{{ item.name }}
              </router-link>
            </div>
            
        </a-menu-item>
        <a-sub-menu :key="item.cd" v-if="item.children.length>0">
          <span slot="title">
            <a-icon v-if="item.meta.icon" :type="item.meta.icon" />
            <a-icon v-else type="menu" />
            <span>{{ item.name }}</span>
          </span>
          <template v-for="(child, i) in item.children">
            <a-menu-item :key="child.cd">
              <router-link :to="child.routerPath"
                >{{ child.name }}
              </router-link>
            </a-menu-item>
          </template>
        </a-sub-menu>
      </template>
    </a-menu>
  </a-layout-sider>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import store from "@/store";
export default {
  data() {
    return {
      selectedItemKey: "",
      openKeys: [],
    };
  },
  computed: {
    ...mapState("user", ["isMenuCollapsed", "routes", "routesTree"]),
    menuData() {
      return this.routesTree;
    },
  },
  mounted() {
    console.log(this.routesTree, 123123213)
  },
  methods: {
    ...mapMutations("user", ["setActivePage"]),
    handleMenuItem(itemObj) {
      this.selectedItemKey = itemObj.key;
      let selectedItem = this.routes.find((item) => {
        return item.cd == itemObj.key;
      });

      //这里去打开tabview标签
      // store.dispatch("user/setMultiPage", selectedItem);
      // this.setActivePage(selectedItem.name);
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      this.openKeys = latestOpenKey ? [latestOpenKey] : [];
    },
    gotoPath(path) {
      const url = `https://${window.location.host}/${path}`;
      window.open(url)
    }
  },
};
</script>

<style scoped lang='scss'>
.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 12px 0 12px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 30px;
    height: 30px;

  }
  .system-title{
    margin-left: 0px;
  }
  .logoName {
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
  }
}
/deep/ .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}
/deep/ .ant-menu-root {
  padding-bottom: 50px;
}
.root-menu{
  display: flex;
  align-items: center;
}
/deep/ .ant-menu-inline-collapsed{
  .root-link{
    opacity: 0;
    width: 100px;

  }
}

.root-link{
    width: 100px;
    display: inline-block;
  }

/deep/ .ant-menu-item a{
  color: rgba(0, 0, 0, 0.65);
}

/deep/ .ant-menu-item-selected a {
  color: #1890ff;
}
</style>