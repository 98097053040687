

let isTabView = false;

if (process.env.NODE_ENV === "development") {
}


if (process.env.NODE_ENV === "production") {
}

export default {
    isTabView
}