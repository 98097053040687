<template>
  <div class="uniTabs">
    <a-tabs
      @contextmenu.native="(e) => onContextmenu(e)"
      :activeKey="activePage"
      :style="`margin: -16px auto 8px,max-width: 900px;`"
      :hide-add="true"
      type="editable-card"
      @change="changePage"
      @edit="editPage"
    >
      <a-tab-pane :key="page.fullPath" v-for="page in pageList">
        <span slot="tab" :pagekey="page.fullPath">{{ page.name }}</span>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      pageList: [],
      linkList: [],
      activePage: '',
    };
  },
  computed: {
    ...mapState("user", ["multiPage", "routes"]),
  },
  watch: {
    '$route': function (newRoute) {
      this.activePage = newRoute.fullPath;
      this.setPageList(newRoute.fullPath);
    },
    multiPage: function (newVal) {
      if (!newVal) {
        this.setRouteList([this.$route.name]);
        debugger;
        this.pageList = [this.$route];
      }
    },
  },
  created() {
  },
  mounted(){
    this.setRouteList([]);
    this.activePage = this.$route.fullPath;
    this.setPageList(this.$route.fullPath);
  },
  methods: {
    ...mapMutations("keepAliveConf", [
      "setRouteList",
      "pushRouteList",
      "popRouteList",
    ]),
    setPageList(fullPath){
      //路由和routes匹配
      const routes = this.routes;
      let pathRoute = {};
      //找到对应菜单的路由
      if(Array.isArray(routes)){
        const pathRoutes = routes.filter(el=>{
          return el.fullPath == fullPath;
        })
        if(pathRoutes.length>0){
          pathRoute = pathRoutes[0]
        }
      }
      //this.pageList是否存在
      if(this.pageList.indexOf(pathRoute) == -1){
        this.pageList.push(pathRoute)
        this.linkList.push(fullPath);
      }
    },
    changePage(key) {
      this.activePage = key;
      this.$router.push(key);
    },
    editPage(key, action) {
      this[action](key);
    },
    remove(key) {
      if (this.pageList.length === 1) {
        this.$message.warning('这是最后一页，不能再关闭了啦')
        return
      }
      let index = this.linkList.indexOf(key);
      this.linkList = this.linkList.filter(item => item !== key);
      this.pageList = this.pageList.filter(item => item.fullPath !== key);

      if (key === this.activePage) {
        index = index >= this.linkList.length ? this.linkList.length - 1 : index;
        this.activePage = this.pageList[index].fullPath;
        this.$router.push(this.activePage);
      }
    }
  },
};
</script>

<style lang='scss' scoped>
  .uniTabs {
    /deep/ .ant-tabs-bar{
      margin:0px;
      border-bottom:0px;
    }
  }
</style>